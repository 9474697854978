/* Import a Google Font for better appearance */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.subjects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
  direction: rtl;
  animation: fadeIn 1s ease-in-out;

}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.subjects-content {
  background: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  margin-top: 20px;
  animation: slideIn 0.8s ease-in-out;
}

@keyframes slideIn {
  from { transform: translateY(50px); }
  to { transform: translateY(0); }
}

.subjects-content h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
}

.subjects-content p {
  text-align: center;
  color: #555;
  margin-bottom: 30px;
  font-size: 18px;
}

.subjects {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  animation: zoomIn 0.8s ease-in-out;
}

@keyframes zoomIn {
  from { transform: scale(0.5); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.subject-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 45%;
  max-width: 400px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-bottom 0.3s ease;
}

.subject-card img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.subject-card h2 {
  color: #007bff;
  margin-bottom: 10px;
  font-size: 24px;
  text-align: center;
}

.subject-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #007bff;
}

.subject-card:hover img {
  transform: scale(1.1);
}

.subject-details h3 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.subject-details p {
  color: #555;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.subject-details ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.subject-details ul li {
  background: #e9ecef;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 18px;
  transition: background-color 0.3s ease, border-bottom 0.3s ease;
}

.subject-details ul li:hover {
  background-color: #d4d9df;
  border-bottom: 3px solid #007bff;
}

.subscribe-button {
  display: block;
  margin: 0 auto;
  padding: 12px 30px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.subscribe-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .subjects-content {
    margin-left: 60px; 
    padding-left: 10px; /* Slight padding for better readability */
  }
}