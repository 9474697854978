.admin-help-container {
    display: flex;
  }
  
  .admin-help-content {
    margin: 20px;
    flex-grow: 1;
    direction: rtl;
    font-family: 'Roboto', sans-serif;
   
    width: calc(100% - 300px); /* Adjust width to account for sidebar */
  }
  
  h1 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  section h2 {
    color: #343a40;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
  }
  
  section p {
    color: #495057;
    line-height: 1.6;
    font-size: 16px;
  }
  
  ol {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  ol li {
    margin-bottom: 10px;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-item h3 {
    color: #007bff;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .faq-item p {
    color: #495057;
    font-size: 16px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  