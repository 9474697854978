@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.admin-dashboard-container {
  display: flex;
}

.admin-dashboard-content {
  /* margin-right: 80px; */
  margin-top: 50px;
  padding: 20px;
  flex-grow: 1;
  direction: rtl;
  font-family: 'Roboto', sans-serif;
 
  background: #f0f2f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-dashboard-content h1 {
  color: #343a40;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.dashboard-widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.widget {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 320px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.widget:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.widget h2 {
  color: #007bff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}

.widget p {
  color: #555;
  font-size: 18px;
  margin-bottom: 10px;
}

.widget button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  margin: 5px;
  
}

.widget button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
