@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.admin-profile-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #f0f2f5;
  min-height: 100vh;
}

/* Profile content */
.admin-profile-content {
  width: 100%;
  max-width: 800px; /* Set a max-width to prevent stretching on large screens */
  padding: 30px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  margin-bottom: 40px;
}

.profile-header {
  text-align: center;
  margin-bottom: 30px;
}

.profile-picture {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}

.profile-header h1 {
  font-size: 28px;
  color: #343a40;
  margin: 0;
}

.profile-details {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.profile-details h2 {
  color: #007bff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}

.profile-details p {
  color: #666;
  font-size: 16px;
  margin: 10px 0;
}

.profile-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.edit-button, .password-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 25px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.edit-button:hover, .password-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.edit-button-link, .password-button-link {
  text-decoration: none;
}

/* Media Queries for responsiveness */

/* Mobile styles */
@media (max-width: 768px) {
  .admin-profile-container {
    padding: 15px;
    margin-top: 20px;
  }

  .admin-profile-content {
    width: 100%;
    padding: 20px;
  }

  .profile-picture {
    width: 120px;
    height: 120px;
  }

  .profile-header h1 {
    font-size: 22px;
  }

  .profile-details h2 {
    font-size: 20px;
  }

  .profile-details p {
    font-size: 14px;
  }

  .edit-button, .password-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .admin-profile-container {
    margin-top: 40px;
  }

  .admin-profile-content {
    width: 90%;
  }

  .profile-picture {
    width: 140px;
    height: 140px;
  }

  .profile-header h1 {
    font-size: 26px;
  }

  .profile-details h2 {
    font-size: 21px;
  }

  .profile-details p {
    font-size: 15px;
  }
}
