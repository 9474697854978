/* Import a Google Font for better appearance */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.help-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
  direction: rtl;
  
}

.help-content {
  background: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  margin-top: 20px;
}

.help-content h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
}

.help-content p {
  text-align: center;
  color: #555;
  margin-bottom: 30px;
  font-size: 18px;
}

.faq-section h3, .contact-section h3, .social-media-section h3 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.faq-section ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.faq-section ul li {
  background: #e9ecef;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.6;
}

.contact-section p {
  text-align: center;
  color: #555;
  margin-bottom: 10px;
  font-size: 18px;
}

.social-media-section {
  text-align: center;
  margin-top: 30px;
}

.social-media-logos {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.social-media-logos img {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.social-media-logos img:hover {
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .help-content {
    margin-left: 60px; /* Adjust this to your needs */
    padding-left: 10px; /* Slight padding for better readability */}
  }