/* Container for the login form */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7fafc;
}

/* The form itself */
.login-form {
  width: 100%;
  max-width: 400px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Form title */
.login-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #2d3748;
}

/* Input group styling */
.input-group {
  display: flex;
  flex-direction: column;
}

/* Label styling */
.input-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #4a5568;
}

/* Input field styling */
.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 16px;
  color: #2d3748;
  transition: border-color 0.2s;
}

.input-field:focus {
  outline: none;
  border-color: #48bb78;
}

/* Submit button styling */
.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #48bb78;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #38a169;
}

/* Register link styling */
.register-link {
  text-align: center;
  font-size: 14px;
  color: #718096;
}

.register-link .link {
  color: #48bb78;
  text-decoration: underline;
}
