.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.footer-logo img {
  width: 120px; /* Adjust the size of the logo for better scaling */
  margin-bottom: 15px;
}

.footer-nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.footer-nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.footer-nav-links a:hover {
  color: #007bff;
}

.footer-social-media {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.footer-social-media img {
  width: 40px; /* Size of the social media icons */
  height: 40px;
  transition: transform 0.3s;
}

.footer-social-media img:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.footer-copyright {
  font-size: 1em;
  color: #777;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
  .footer {
    padding: 15px;
  }
  
  .footer-logo img {
    width: 100px; /* Slightly smaller logo for smaller screens */
  }
  
  .footer-nav-links a {
    font-size: 1em; /* Smaller text on mobile */
  }
  
  .footer-social-media img {
    width: 30px; /* Smaller icons for mobile */
    height: 30px;
  }
}

@media screen and (max-width: 480px) {
  .footer-nav-links {
    flex-direction: column;
    gap: 10px;
  }
  
  .footer-social-media {
    gap: 10px;
  }
  
  .footer-nav-links a {
    font-size: 0.9em; /* Further reduce text size on very small screens */
  }
}
