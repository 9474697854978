/* Register.css */

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 120px;
  }
  
  .registration-form {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .registration-form h1 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .registration-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .registration-form input,
  .registration-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .registration-form small {
    display: block;
    margin-top: -15px;
    margin-bottom: 20px;
    color: #666;
  }
  
  .registration-form button {
    width: 100%;
    padding: 10px;
    background: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .registration-form button:hover {
    background: #218838;
  }
  
  .registration-form p {
    text-align: center;
  }
  
  .registration-form a {
    color: #007bff;
    text-decoration: none;
  }
  
  .registration-form a:hover {
    text-decoration: underline;
  }
  