@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.admin-change-password-container {
  display: flex;
}

.admin-change-password-content {
  margin-right: 80px;
  padding: 20px;
  flex-grow: 1;
  direction: rtl;
  font-family: 'Roboto', sans-serif;
  
  background: #f0f2f5;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-change-password-content h1 {
  color: #007bff;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.change-password-form {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  color: #343a40;
  font-size: 18px;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.save-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
}

.save-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
