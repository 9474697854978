.lesson-detail-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 80px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.restricted-message{
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 80px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
h1, h2 {
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.lesson-image img {
  display: block;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lesson-videos {
  margin-top: 20px;
}

.lesson-videos iframe {
  display: block;
  width: 100%;
  max-width: 640px;
  height: 360px;
  margin: 10px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lesson-powerpoint iframe {
  display: block;
  width: 100%;
  max-width: 640px;
  height: 360px;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

p {
  text-align: center;
  color: #555;
  font-size: 16px;
  margin: 20px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .lesson-detail-container {
    padding: 15px;
  }

  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 18px;
  }

  .lesson-videos iframe,
  .lesson-powerpoint iframe {
    height: 240px;
  }
}
